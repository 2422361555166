import { EventEmitter } from "events";

export class Stream extends EventEmitter {
  data;
  constructor() {
    super();
    this.data = "";
  }

  write(newData) {
    this.data = this.data.concat(newData);
  }

  end() {
    this.emit("finish");
    return this.data;
  }

  getData() {
    return this.data;
  }
}
