<template>
  <div id="app">
    <b-navbar style="height: 40px;" type="dark" variant="dark" sticky>
      <b-navbar-brand href="#" variant="light">
        Disputatron
        <span v-if="refs">
          <span v-if="!isEditTitle">- {{ title ? title : "Untitled" }}</span>
          <input v-if="isEditTitle" ref="title-edit" :value="title" placeholder="Enter project name">
        </span>
      </b-navbar-brand>
      <b-navbar-nav v-if="refs">
        <b-button v-if="!isEditTitle" size="sm" class="mr-2" @click="isEditTitle = true" variant="primary">Edit</b-button>
        <b-button v-if="isEditTitle" size="sm" class="mr-2" @click="saveTitle" variant="success">Save</b-button>
        <b-button v-if="isEditTitle" size="sm" class="mr-2" @click="isEditTitle = false" variant="danger">Cancel</b-button>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-button size="sm" class="mr-2" @click="openHelp" variant="info">
          Help
        </b-button>
        <b-button v-if="refs" size="sm" variant="danger" v-b-modal.close-warning>Close</b-button>
      </b-navbar-nav>
    </b-navbar>

    <!-- Toolbar -->
    <div class="sticky-toolbar">
      <TheToolbar :isOpen="isOpen" @close="close()" />
    </div>

    <b-container class="mt-5">
      <b-row>
        <b-col>
          <div class="logo">
            <b-icon
              style="width: 150px; height: 150px;"
              icon="arrows-angle-contract"
            ></b-icon>
            <h2>DISPUTATRON</h2>
          </div>
          <div class="stats-input">
            <b-form-input
              v-model="totalArticles"
              style="text-align: center"
              placeholder="Total Number of Articles Screened (Optional)"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Reviewer 1 Include:</p>
          <div class="name-input">
            <b-form-input
              :value="reviewerNames[0]"
              @change="(e) => $store.commit('metadata/updateReviewerName', { index: 0, name: e })"
              placeholder="Reviewer 1 Name (Optional)"
            ></b-form-input>
          </div>
          <b-form-file
            class="logo"
            v-model="files[0]"
            :state="files[0].name ? true : false"
            placeholder="Choose a library or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".xml, .csv, .json, .nbib, .ris, .tsv"
          ></b-form-file>
        </b-col>
        <b-col>
          <p>Reviewer 2 Include:</p>
          <div class="name-input">
            <b-form-input
              :value="reviewerNames[1]"
              @change="(e) => $store.commit('metadata/updateReviewerName', { index: 1, name: e })"
              placeholder="Reviewer 2 Name (Optional)"
            ></b-form-input>
          </div>
          <b-form-file
            v-model="files[1]"
            :state="files[1].name ? true : false"
            placeholder="Choose a library or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".xml, .csv, .json, .nbib, .ris, .tsv"
          ></b-form-file>
        </b-col>
      </b-row>
      <b-row>
        <div id="combine-arrow"></div>
      </b-row>
      <b-row>
        <b-col>
          <b-icon id="down-arrow" icon="arrow-down"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button-group>
            <b-button v-on:click="submit">Submit</b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <div class="m-3" v-if="refs && totalArticles && !isLoading">
        <h3>Accuracy Measures</h3>
        <b-table
          class="light-table"
          bordered
          :fields="accFields"
          :items="accMeasure"
          head-variant="light"
        ></b-table>
      </div>

      <b-row>
        <b-col class="p-3">
          <div v-if="refs && !isLoading">
            <h3>Screening refs</h3>
            <b-form-group>
              <b-form-radio v-model="showAgreements" :value="false">
                Display just screening conflicts ({{ this.refs.filter(el => el.isConflict).length }})
              </b-form-radio>
              <b-form-radio v-model="showAgreements" :value="true">
                Display both screening conflicts and agreements ({{
                  this.refs.length
                }})
              </b-form-radio>
            </b-form-group>
            <b-button variant="primary" v-b-modal.export>
              Export
            </b-button>
            <div>
              <b-table
                class="light-table m-2"
                style="text-align: left;"
                bordered
                :items="displayArticles"
                :fields="fields"
                :tbody-tr-class="rowClass"
                head-variant="light"
              >
                <template v-slot:cell(include)="data">
                  <b-form-checkbox
                    v-model="data.item.include"
                    @input="e => $store.commit('references/updateRef', { index: data.item.index, include: e })"
                    switch
                  >
                    {{ data.item.include ? "Include" : "Exclude" }}
                  </b-form-checkbox>
                </template>

                <template v-slot:cell(authors)="data">
                  {{ formatArray(data.value) }}
                </template>

                <template v-slot:cell(abstract)="data">
                  <b-button size="sm" @click="data.toggleDetails" class="mr-2">
                    {{ data.detailsShowing ? "Hide" : "Show" }} Abstract
                  </b-button>
                </template>

                <template v-slot:row-details="data">
                  <b-card bg-variant="secondary" text-variant="white">
                    {{ data.item.abstract }}
                  </b-card>
                </template>
              </b-table>
            </div>

            <b-button variant="primary" v-b-modal.export>
              Export
            </b-button>
          </div>
          <b-spinner v-else-if="isLoading"></b-spinner>
        </b-col>
      </b-row>
    </b-container>
    <b-modal title="Export" id="export" :hide-footer="true">
      <ExportModal />
    </b-modal>
  </div>
</template>

<script>
import ExportModal from "./components/ExportModal.vue";
import TheToolbar from "./components/TheToolbar.vue";
import * as reflib from "reflib";
import { fileTypeOptions } from "./types";
import axios from "axios";
import localforage from "localforage";

import { mapState } from "vuex";

export default {
  components: {
    ExportModal,
    TheToolbar
  },
  data() {
    return {
      files: [new File([], ""), new File([], "")],
      isLoading: false,
      isEditTitle: false,
      // Variables to store the input/output URL for SRA integration
      in: [],
      out: null,
      // Variable to store whether to show refs only or refs and agreements
      showAgreements: false,
      fields: [
        {
          key: "include"
        },
        {
          key: "year",
          sortable: true
        },
        {
          key: "title",
          sortable: true
        },
        {
          key: "authors",
          sortable: true
        },
        {
          key: "abstract"
        },
        {
          key: "conflict",
          sortable: true
        }
      ],
      accFields: [
        {
          key: "kappa",
          label: "Cohen's Kappa coefficient"
        },
        {
          key: "pabak",
          label: "PABAK coefficient"
        },
        {
          key: "agree",
          label: "Percentage of article agreements"
        },
        {
          key: "includeAgree",
          label: "Proportion of agreed included articles"
        },
        {
          key: "excludeAgree",
          label: "Proportion of agreed excluded articles"
        },
        {
          key: "conflicting",
          label: "Proportion of article disagreements"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      options: state => state.options
    }),
    // Vuex Members
    title: {
      get() {
        return this.$store.state.metadata.title;
      },
      set(title) {
        this.$store.commit('metadata/setTitle', title);
      }
    },
    reviewerNames: {
      get() {
        return this.$store.state.metadata.reviewerNames;
      },
      set(reviewerNames) {
        this.$store.commit('metadata/setReviewerNames', reviewerNames);
      }
    },
    refs: {
      get() {
        return this.$store.state.references.refs
      },
      set(refs) {
        this.$store.commit('references/setRefs', refs)
      }
    },
    totalArticles: {
      get() {
        return this.$store.state.references.totalArticles
      },
      set(totalArticles) {
        this.$store.commit('references/setTotalArticles', totalArticles)
      }
    },
    // Computed
    isOpen() {
      if (this.refs || this.totalArticles) {
        return true;
      } else {
        return false;
      }
    },
    displayArticles() {
      if (this.showAgreements) {
        return this.refs;
      } else {
        return this.refs.filter(el => el.isConflict);
      }
    },
    accMeasure() {
      const numberAgreed = this.refs.filter(el => !el.isConflict).length;
      const numberConflicts = this.refs.filter(el => el.isConflict).length;

      if (this.refs && this.totalArticles) {
        const kappa = this.calcKappa();
        const includeAgree = numberAgreed / this.totalArticles;
        const excludeAgree =
          (this.totalArticles -
            numberAgreed -
            numberConflicts) /
          this.totalArticles;
        const agree = (includeAgree + excludeAgree) * 100;
        const pabak = 2 * (includeAgree + excludeAgree) - 1;

        const agreeString = `${agree.toFixed(2)}%`;
        const includeAgreeString = `${numberAgreed} / ${this.totalArticles}`;
        const excludeAgreeString = `${this.totalArticles - numberAgreed - numberConflicts} /
          ${this.totalArticles}`;
        const conflictString = `${numberConflicts} / ${this.totalArticles}`;
        return [
          {
            kappa: kappa.toFixed(3),
            pabak: pabak.toFixed(3),
            agree: agreeString,
            includeAgree: includeAgreeString,
            excludeAgree: excludeAgreeString,
            conflicting: conflictString
          }
        ];
      } else {
        return [];
      }
    }
  },
  async created() {
    // Local Storage
    const metadata = await localforage.getItem("metadata");
    const refs = await localforage.getItem("refs");
    const totalArticles = await localforage.getItem("totalArticles");
    if (metadata) {
      this.title = metadata.title;
      this.reviewerNames = metadata.reviewerNames;
    }
    if (refs) {
      this.refs = refs;
    }
    if (totalArticles) {
      this.totalArticles = totalArticles;
    }
    // Download from URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.in.push(urlParams.get("in1"));
    this.in.push(urlParams.get("in2"));
    this.out = urlParams.get("out");
    if (this.in[0] && this.in[1] && this.out) {
      console.log("Downloading XML");
      // TEST URL:
      // http://localhost:8080/?in1=http://download1584.mediafire.com/c6r8cispnokg/cc44vua6ee21w5j/Refrree1.xml&in2=http://download1502.mediafire.com/qrd4z7o0tdrg/6kx57bvy8vsb2po/Refrree2.xml&out=URL3
      // Download both input files and pass result to data array as string of xml
      Promise.all(
        this.in.map(url =>
          Promise.resolve()
            .then(() => this.readURL(url))
            .then(contents => this.computeHashes(contents))
        )
      )
        .then(libraries => {
          this.refs = this.findConflicts(libraries[0], libraries[1]);
        })
        .catch(err => console.log(err));
    }
  },
  methods: {
    saveTitle() {
      this.$store.commit('metadata/setTitle', this.$refs['title-edit'].value);
      this.isEditTitle = false;
    },
    calcKappa() {
      if (this.totalArticles && this.refs) {
        const numberAgreed = this.refs.filter(el => !el.isConflict).length;
        const numberConflicts = this.refs.filter(el => el.isConflict).length;

        const a = numberAgreed;
        const b = this.refs.filter(
          val =>
            val.conflict ===
            "Included by " +
              (this.reviewerNames[0] ? this.reviewerNames[0] : "Reviewer 1")
        ).length;
        const c = this.refs.filter(
          val =>
            val.conflict ===
            "Included by " +
              (this.reviewerNames[1] ? this.reviewerNames[1] : "Reviewer 2")
        ).length;
        const d =
          this.totalArticles -
          numberAgreed -
          numberConflicts;

        const p0 = (a + d) / this.totalArticles;
        const pc =
          ((a + b) / this.totalArticles) * ((a + c) / this.totalArticles);
        const pi =
          ((c + d) / this.totalArticles) * ((b + d) / this.totalArticles);
        const pe = pc + pi;

        return (p0 - pe) / (1 - pe);
      } else {
        console.log("Error Calculating Kappa");
        return 0;
      }
    },
    close() {
      this.$store.dispatch('resetStore');
    },
    openHelp() {
      window.open("http://sr-accelerator.com/#/help/disputatron", "_blank");
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.include) return "table-info";
    },
    formatArray(arr) {
      let string = "";
      if (arr.length > 0) {
        for (let i = 0; i < arr.length - 1; i++) {
          string = string.concat(arr[i] + ", ");
        }
        string = string.concat(arr[arr.length - 1]);
      }
      return string;
    },
    readURL(url) {
      return new Promise((resolve, reject) => {
        if (url) {
          axios
            .get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(err => reject(err));
        } else {
          reject("Missing url in url parameters");
        }
      });
    },
    readFile(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => resolve(reader.result);
      });
    },
    getFileExtention(name) {
      return "." + name.split(".").pop();
    },
    hashCompute(ref) {
      return [ref.year, ref.title, ref.volume].filter(i => i).join("-");
    },
    computeHashes(contents, fileType) {
      let refs = [];
      return new Promise(resolve => {
        reflib
          .parse(fileType.driver, contents)
          .on("ref", ref => refs.push(ref))
          .on("error", err => {
            alert(err);
            this.isLoading = false;
          })
          .on("end", () => {
            // Compute hashes for each library and sort by hash
            refs.map(ref => {
              ref.hash = this.hashCompute(ref);
            });
            refs = refs.sort(this.compareHash);
            resolve(refs);
          });
      });
    },
    compareHash(a, b) {
      if (a.hash && b.hash) {
        if (a.hash < b.hash) {
          return -1;
        }
        if (a.hash > b.hash) {
          return 1;
        }
        return 0;
      } else {
        throw Error("No hash calculated for ref");
      }
    },
    findConflicts(list1, list2) {
      const refs = [];
      let conflictsIndex = 0;
      // Move backwards through list
      let l1Offset = list1.length - 1;
      let l2Offset = list2.length - 1;
      while (l1Offset > -1 || l2Offset > -1) {
        const ref1 = list1[l1Offset] ? list1[l1Offset] : null;
        const ref2 = list2[l2Offset] ? list2[l2Offset] : null;
        // Both agree
        if (ref1?.hash === ref2?.hash) {
          refs.push({
            ...ref1,
            isConflict: false,
            conflict: "Included by both",
            include: true,
            index: conflictsIndex
          })
          conflictsIndex++;
          l1Offset--;
          l2Offset--;
          continue;
        }
        // List 2 is missing something
        else if ((ref1?.hash || "") > (ref2?.hash || "")) {
          refs.push({
            ...ref1,
            isConflict: true,
            conflict:
              "Included by " +
              (this.reviewerNames[0] ? this.reviewerNames[0] : "Reviewer 1"),
            include: false,
            index: conflictsIndex
          });
          conflictsIndex++;
          l1Offset--;
          continue;
        }
        // List 1 is missing sometthing
        else if ((ref1?.hash || "") < (ref2?.hash || "")) {
          refs.push({
            ...ref2,
            isConflict: true,
            conflict:
              "Included by " +
              (this.reviewerNames[1] ? this.reviewerNames[1] : "Reviewer 2"),
            include: false,
            index: conflictsIndex
          });
          conflictsIndex++;
          l2Offset--;
          continue;
        }
      }
      return refs;
    },
    submit() {
      this.isLoading = true;
      // Check that both files exist
      for (const file of this.files) {
        if (file.name === "") {
          console.log("Error: Both files must be specified");
          alert("Both files must be specified");
          this.isLoading = false;
          return;
        }
      }
      Promise.all(
        this.files.map(file => {
          // Get file extention and necessary driver
          const fileExtention = this.getFileExtention(file.name);
          const fileType = fileTypeOptions.find(
            ({ value }) => value.extention === fileExtention
          )?.value;
          // If unsupported file type
          if (typeof fileType === "undefined")
            console.log("Error: Unsuported file extention");
          this.$store.commit('options/setFileType', fileType);

          return Promise.resolve()
            .then(() => this.readFile(file))
            .then(contents => this.computeHashes(contents, fileType))
        })
      ).then(libraries => {
        this.refs = this.findConflicts(libraries[0], libraries[1]);
        this.isLoading = false;
      });
    }
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  background-color: rgb(250, 250, 250);
  min-height: 100vh;
}

.sticky-toolbar {
  position: fixed;
  z-index: 5;
  width: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.light-table {
  background-color: rgb(255, 255, 255);
}

.logo {
  color: #4c0080;
}

#combine-arrow {
  margin-right: 10em;
  margin-left: 10em;
  border: 8px solid #2c3e50;
  border-style: hidden solid solid solid;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 100px;
}

#down-arrow {
  width: 100px;
  height: 100px;
  color: #2c3e50;
  margin-top: -10px;
}

.name-input {
  padding: 10px 10px 10px 10px;
}

.stats-input {
  max-width: 400px;
  margin: 40px auto;
}
</style>
