import localforage from 'localforage'
import filenameMixin from './filenameMixin';

export default {
  mixins: [filenameMixin],
  methods: {
    async save() {
      const metadata = await localforage.getItem("metadata");
      const refs = await localforage.getItem("refs");
      const totalArticles = await localforage.getItem("totalArticles");

      const saveObject = {
        metadata,
        refs,
        totalArticles
      };

      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(saveObject));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        "disp_"
          + this.getProjectName()
          + " "
          + this.getDateString()
          + ".disp"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }
}