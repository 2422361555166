import localforage from "localforage";
import Vue from "vue";

const state = {
  refs: null,
  totalArticles: null
};

const getters = {
  getRefs: state => {
    return state.refs;
  },
  getTotalArticles: state => {
    return state.totalArticles;
  }
};

const mutations = {
  reset(state) {
    state.refs = null;
    state.totalArticles = null;
    localforage.setItem("refs", null);
    localforage.setItem("totalArticles", null);
  },
  setRefs(state, refs) {
    state.refs = refs;
    localforage.setItem("refs", state.refs);
  },
  concatRefs(state, refs) {
    state.refs = state.refs.concat(refs);
    localforage.setItem("refs", state.refs);
  },
  updateRef(state, data) {
    const i = data.index;
    // Update reference object at index i with new include value
    Vue.set(state.refs, i, { ...state.refs[i], include: data.include });
    localforage.setItem("refs", state.refs);
  },
  setTotalArticles(state, totalArticles) {
    state.totalArticles = totalArticles;
    localforage.setItem("totalArticles", state.totalArticles);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
