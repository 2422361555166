export default {
  methods: {
    legacyLoad(data) {
      this.$store.commit('references/setRefs', []);
      if (data.agreedArticles) {
        // Map agreedArticles to have isConflict false
        data.agreedArticles = data.agreedArticles.map(article => {
          article.isConflict = false;
          return article;
        });
        this.$store.commit('references/setRefs', data.agreedArticles);
      }
      if (data.conflicts) {
        // Map refs to have conflict true
        data.conflicts = data.conflicts.map(article => {
          article.isConflict = true;
          return article;
        });
        this.$store.commit('references/concatRefs', data.conflicts);
      }
    },
    load(file) {
      const reader = new FileReader();
      reader.onload = e => {
        if (typeof e.target?.result === "string") {
          const data = JSON.parse(e.target.result);
          // Load data into screenatron
          if (data.metadata) {
            this.$store.commit('metadata/setMetadata', data.metadata);
          } else {
            this.$store.commit('metadata/reset');
          }
          if (data.totalArticles) {
            this.$store.commit('references/setTotalArticles', data.totalArticles);
          } else {
            this.$store.commit('references/setTotalArticles', null);
          }
          if (data.refs) {
            this.$store.commit('references/setRefs', data.refs);
          } else {
            this.$store.commit('references/setRefs', null);
          }
          // Check if should legacy load or load normally
          if (data.agreedArticles || data.conflicts) {
            this.legacyLoad(data);
          }
        }
      };
      // localforage.clear();
      reader.readAsText(file);
    },
  }
}