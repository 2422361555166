import Vue from "vue";
import Vuex from "vuex";
import localforage from 'localforage'

import metadata from "./modules/metadata";
import options from "./modules/options";
import references from "./modules/references";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    metadata,
    options,
    references
  },
  actions: {
    resetStore({ commit }) {
      commit("metadata/reset");
      commit("options/reset");
      commit("references/reset");
      localforage.clear();
    }
  }
});
