import Vue from 'vue';
import localforage from 'localforage'

const getDefaultMetadata = () => {
    return {
        // Project name
        title: "",
        // reviewer names
        reviewerNames: ["", ""]
    }
}

const state = getDefaultMetadata();

const getters = {
  // Get the entire refs object
  getTitle: state => {
    return state.title;
  },
  getReviewerNames: state => {
      return state.reviewerNames;
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultMetadata());
    localforage.setItem("metadata", getDefaultMetadata());
  },
  // Set metadata
  setMetadata(state, data) {
    // Set metadata
    Object.assign(state, data);
    // Update local storage
    localforage.setItem("metadata", state);
  },
  // Set title
  setTitle(state, title) {
    // Set title
    state.title = title;
    // Update local storage
    localforage.setItem("metadata", state);
  },
  // Set reviewer names
  setReviewerNames(state, reviewerNames) {
    state.reviewerNames = reviewerNames;
    // Update local storage
    localforage.setItem("metadata", state);
  },
  // Set a single reviewer name
  updateReviewerName(state, data) {
    // Set name
    Vue.set(state.reviewerNames, data.index, data.name);
    // Update local storage
    localforage.setItem("metadata", state);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}