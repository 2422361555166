import * as reflib from "reflib";
import { mapState } from "vuex";
import { Stream } from "../classes";
import fileNameMixin from "./filenameMixin";

export default {
  mixins: [fileNameMixin],
  computed: {
    ...mapState({
      refs: state => state.references.refs,
      options: state => state.options
    }),
  },
  methods: {
		downloadDiscarded() {
			const discardedArticles = this.refs.filter(
				article => !article.include
			);
			this.downloadXml(discardedArticles, "discarded");
		},
    downloadIncluded() {
      if (this.refs) {
				// Filter through refs and only select those to be included
        const includedArticles = this.refs.filter(
          article => article.include
        );
        // Map the conflict message to a field for the XML file
        includedArticles.map(article => {
          if (this.options.conflictField) {
            article[this.options.conflictField] = article.conflict;
          }
        });
				// Download
				this.downloadXml(includedArticles, this.options.fileName);
			}
    },
		downloadXml(refs, name) {
			// Generate xml file
			const xmlStream = new Stream();
			reflib
				.output({
					format: this.options.fileType.driver,
					stream: xmlStream,
					content: refs
				})
				.on("finish", () => {
					const filename =
						this.getProjectName()
							+ "_"
							+ name
							+ " "
							+ this.getDateString()
							+ this.options.fileType.extention;
					const pom = document.createElement("a");
					const bb = new Blob([xmlStream.getData()], { type: "text/plain" });

					pom.setAttribute("href", window.URL.createObjectURL(bb));
					pom.setAttribute("download", filename);

					pom.dataset.downloadurl = [
						"text/plain",
						pom.download,
						pom.href
					].join(":");
					pom.draggable = true;
					pom.classList.add("dragout");

					pom.click();
				});
		}
  }
}